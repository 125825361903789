<template>
  <v-container
    fluid
    id="regular-table"
    class="mt-4"
  >
    <div
      class="d-flex flex-column flex-md-row mx-2"
      style="gap: 20px"
    >
      <div class="flex-grow-1">
        <h4 class="text-h4 mb-4">Order Details</h4>
        <div class="ml-auto me-6">
          <div
            class="text-h5 d-flex justify-space-between pa-4"
            style="min-width: 200px"
          >
            <span>{{ $t("shelf") }}</span>
            <span>{{ inbound?.supplyOrder?.shelf?.name ?? "-" }}</span>
          </div>
          <v-divider></v-divider>
          <div
            class="text-h5 d-flex justify-space-between pa-4"
            style="min-width: 200px"
          >
            <span>{{ $t("provider") }}</span>
            <span>{{ inbound?.fromProvider?.name ?? "-" }}</span>
          </div>
          <v-divider></v-divider>

          <div v-if="inbound?.supplyOrder">
            <div
              class="text-h5 d-flex justify-space-between pa-4"
              style="min-width: 200px"
            >
              <span>{{ $t("supply_order_id") }}</span>

              <router-link
                v-if="$admin.can('supplyOrder-view')"
                :to="{
                  name: 'supplyOrders.view',
                  params: { id: inbound?.supplyOrder?.id },
                }"
                target="_blank"
              >
                {{ inbound?.supplyOrder?.tracking_number ?? "-" }}
              </router-link>
              <span v-else>
                {{ inbound?.supplyOrder?.tracking_number ?? "-" }}
              </span>
            </div>
            <v-divider></v-divider>
          </div>
          <div v-if="$admin.can('supplyOrder-print') && inbound?.supplyOrder">
            <div
              class="text-h5 d-flex justify-space-between pa-4"
              style="min-width: 200px"
            >
              <span>{{ $t("purchase_order") }}</span>
              <v-chip
                small
                color="primary"
                class="my-auto"
                :disabled="is_loading_invoice"
                :loading="is_loading_invoice"
                @click="downloadPurchaseOrder(inbound?.supplyOrder)"
              >
                {{ "order-" + inbound?.supplyOrder?.tracking_number + ".pdf" }}
                <v-icon right> mdi-eye </v-icon></v-chip
              >
            </div>
            <v-divider></v-divider>
          </div>
          <dir
            v-if="
              inbound?.inboundStatus?.name == 'closed' && inbound?.supplyOrder
            "
          >
            <div
              class="text-h5 d-flex justify-space-between pa-4"
              style="min-width: 200px"
            >
              <span>{{ $t("receipt_slip") }}</span>
              <v-chip
                small
                color="primary"
                class="my-auto"
                :disabled="is_loading_invoice"
                :loading="is_loading_invoice"
                @click="downloadInboundInvoice(inbound)"
              >
                {{ "inbound-" + inbound?.tracking_number + ".pdf" }}
                <v-icon right> mdi-eye </v-icon></v-chip
              >
            </div>
            <v-divider></v-divider>
          </dir>
          <div
            class="text-h5 d-flex justify-space-between pa-4"
            style="min-width: 200px"
          >
            <span>{{ $t("status") }}</span>
            <span class="px-4 grey--text">{{
              inbound?.inboundStatus?.name?.toUpperCase() ?? "-"
            }}</span>
          </div>
          <v-divider></v-divider>

          <h3 class="text-h4 mb-4 mt-16">
            {{ $t("pricing_details") }}
          </h3>
          <v-row>
            <v-col
              sm="6"
              md="6"
            >
              <div
                class="text-h5 d-flex justify-space-between pa-4"
                style="min-width: 100px"
              >
                <v-span>{{ $t("invoice_amount_ht") }}</v-span>
                <v-span>{{ inbound.invoice_amount_ht }}</v-span>
              </div>
              <v-divider></v-divider>
            </v-col>
            <v-col
              sm="6"
              md="6"
            >
              <div class="text-h5 d-flex justify-space-between pa-4">
                <v-span>{{ $t("invoice_amount_ttc") }}</v-span>
                <v-span>{{ inbound.invoice_amount_ttc }}</v-span>
              </div>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row class="mt-n3">
            <v-col
              sm="6"
              md="6"
            >
              <div
                class="text-h5 d-flex justify-space-between pa-4"
                style="min-width: 100px"
              >
                <v-span>{{ $t("receipt_amount_ht") }}</v-span>
                <v-span>{{ inbound.reception_amount_ht }}</v-span>
              </div>
              <v-divider></v-divider>
            </v-col>
            <v-col
              sm="6"
              md="6"
            >
              <div class="text-h5 d-flex justify-space-between pa-4">
                <v-span>{{ $t("receipt_amount_ttc") }}</v-span>
                <v-span>{{ inbound.reception_amount_ttc }}</v-span>
              </div>
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="flex-grow-1">
        <h4 class="text-h4 mb-4">Delivery Details</h4>
        <div class="ml-auto me-6">
          <div
            class="text-h5 d-flex justify-space-between pa-4"
            style="min-width: 200px"
          >
            <span>{{ $t("delivery_type") }}</span>
            <span>{{ inbound?.supplyOrder?.delivery_type ?? "-" }}</span>
          </div>
          <v-divider></v-divider>
          <div
            class="text-h5 d-flex justify-space-between pa-4"
            style="min-width: 200px"
          >
            <span>{{ $t("hub") }}</span>
            <span>{{ inbound?.toHub?.name }}</span>
          </div>
          <v-divider></v-divider>
          <div
            class="text-h5 d-flex justify-space-between pa-4"
            style="min-width: 200px"
          >
            <span>{{ $t("delivery_date") }}</span>
            <span>{{ inbound?.expected_delivery_date }}</span>
          </div>
          <v-divider></v-divider>
          <div v-if="inbound?.received_at">
            <div
              class="text-h5 d-flex justify-space-between pa-4"
              style="min-width: 200px"
            >
              <span>{{ $t("reception_date") }}</span>
              <span>{{ inbound?.received_at }}</span>
            </div>
            <v-divider></v-divider>
          </div>
          <div
            class="text-h5 d-flex justify-space-between pa-4"
            style="min-width: 200px"
          >
            <span>{{ $t("invoice_number") }}</span>
            <span>{{ inbound?.invoice_number }}</span>
          </div>
          <v-divider></v-divider>
          <div
            class="text-h5 d-flex justify-space-between pa-4"
            style="min-width: 200px"
          >
            <span>{{ $t("delivery_order_number") }}</span>
            <span>{{ inbound?.delivery_order_number }}</span>
          </div>
          <v-divider></v-divider>
        </div>
      </div>
    </div>
    <v-container fluid>
      <v-card
        elevation="0"
        class="mt-4"
      >
        <h4 class="text-h4 py-2">
          {{ $t("items_list") }} ({{ inbound.products?.length }})
        </h4>
        <v-data-table
          show-expand
          single-expand
          :headers="cart_headers"
          item-key="name"
          :items="
            inbound.products?.map((product) => {
              return {
                ...product,
                total_price_ttc: Number(product.total_price).toFixed(2),
              };
            })
          "
          class="elevation-0"
          :footer-props="{ 'items-per-page-options': [5, 10] }"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="grey"
            >
              <v-simple-table
                class="my-5 grey"
                dense
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">{{ $t("product_id") }}</th>
                      <th class="text-left">{{ $t("barcode") }}</th>
                      <th class="text-left">
                        {{ $t("pcb") }}
                      </th>
                      <th class="text-left">{{ $t("discount") }}</th>
                      <th class="text-left">
                        {{ $t("discount_price") }}
                      </th>
                      <th class="text-left">{{ $t("tva") }}</th>
                      <th class="text-left">{{ $t("total_price") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ item.generated_code ?? item.code }}</td>
                      <td>{{ item.barcode }}</td>
                      <td>
                        <span v-if="item.qte_in_packaging > 1">
                          {{ item.qte_in_packaging }}
                        </span>
                        <span v-else> - </span>
                      </td>
                      <td>{{ item.discount }}</td>
                      <td>{{ item.discount_price }}</td>
                      <td>{{ item.inbound_tva }}</td>
                      <td>{{ item.total_price }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
          <template v-slot:top> </template>
          <template v-slot:item="{ item, expand, isExpanded }">
            <tr>
              <td>
                <v-icon @click="expand(!isExpanded)">{{
                  isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </td>
              <td>
                <template>
                  <v-img
                    v-if="item.image"
                    :src="item.image.url"
                    max-height="50"
                    max-width="50"
                  />
                  <v-img
                    v-else
                    src="@/assets/product.png"
                    max-height="50"
                    max-width="50"
                  />
                </template>
              </td>
              <td>{{ item.name }}</td>
              <td>
                <template>
                  <span v-if="item.qte_in_packaging > 1">
                    {{ item.qte_by_type_packaging }}
                  </span>
                  <span v-else> - </span>
                </template>
              </td>
              <td>{{ item.qte_in_supply_order }}</td>
              <td>{{ item.inbound_price_ht }}</td>
              <td>
                <template>
                  <span v-if="item.discount > 0">
                    {{ item.discount + "%" }}
                  </span>
                  <span v-else> N/A </span>
                </template>
              </td>

              <td>
                {{
                  Math.round(
                    item.inbound_price_ht *
                      item.qte_in_inbound *
                      (1 - parseFloat(item.discount) / 100) *
                      100
                  ) / 100
                }}
              </td>
              <td>{{ item.batches[0]?.qte_in_lot }}</td>
              <td>{{ item.batches[0]?.lot_number }}</td>
              <td>{{ item.batches[0]?.expiration_date }}</td>
            </tr>
            <tr
              v-for="(lot, index) in item.batches"
              v-bind:key="'lot' + index + '-' + item.id"
            >
              <template v-if="index > 0">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ item.batches[index]?.qte_in_lot }}</td>
                <td>{{ item.batches[index]?.lot_number }}</td>
                <td>{{ item.batches[index]?.expiration_date }}</td>
              </template>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <th class="grey--text">
                {{ $t("total_received") }}
              </th>
              <td>{{ item.qte_in_inbound }}</td>
              <td></td>
              <td></td>
            </tr>
          </template>
        </v-data-table>
        <label>{{ $t("last_edited") + " : " + inbound.updated_at }} </label>
        <a
          text
          @click="show_details = !show_details"
        >
          {{ show_details ? $t("hide_details") : $t("show_details") }}
          <v-icon>{{
            show_details ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </a>
        <v-expand-transition>
          <v-col
            cols="8"
            v-show="show_details"
          >
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("product_name") }}</th>
                    <th class="text-left">{{ $t("updated_by") }}</th>
                    <th class="text-left">{{ $t("updated_at") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="product in inbound.products">
                    <tr
                      v-for="history in product.prices_history"
                      :key="history.update_at"
                    >
                      <td>{{ product.name }}</td>
                      <td>{{ history.updated_by }}</td>
                      <td>{{ history.updated_at }}</td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-expand-transition>
      </v-card>
    </v-container>
    <div class="ma-5 d-flex justify-space-between">
      <v-btn
        outlined
        color="primary"
        @click="returnToList()"
      >
        {{ $t("back") }}
      </v-btn>
      <v-btn
        color="primary"
        v-if="canReopen(inbound)"
        @click="reopenInbound(inbound)"
        :loading="is_loading"
        :disabled="is_loading"
      >
        {{ $t("reopen_inbound") }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import { inboundPermissions } from "@/mixins/permissions";
import { mapGetters } from "vuex";
import inboundProductService from "@/store/services/inbound-product-service";
import supplyOrderService from "@/store/services/supply-order-service";
export default {
  mixins: [validationRules, inboundPermissions],

  computed: {
    console: () => console,
    ...mapGetters({}),
    inbound: {
      get() {
        return this.$store.state.inboundProducts.inbound;
      },
      set(value) {
        this.$store.commit("inboundProducts/SET_INBOUND", value);
      },
    },
  },
  data() {
    return {
      tabs: true,
      is_loading_invoice: false,
      is_loading: false,
      show_details: false,
      cart_headers: [
        { text: this.$t("image"), value: "image", align: "start" },
        {
          text: this.$t("name"),
          sortable: false,
          value: "name",
        },
        { text: this.$t("ordered_packages"), value: "qte_by_type_packaging" },
        {
          text: this.$t("ordered_qte"),
          value: "qte_in_supply_order",
          sortable: false,
          width: 80,
        },
        {
          text: this.$t("unit_price_ht"),
          value: "inbound_price_ht",
          sortable: false,
          width: 80,
        },
        {
          text: this.$t("discount"),
          value: "discount",
          sortable: false,
          width: 80,
        },
        {
          text: this.$t("total_price_ht"),
          value: "total_price_ht",
          sortable: false,
        },

        {
          text: this.$t("received_quantity"),
          value: "qte_in_lot",
          sortable: false,
          width: 90,
        },
        { text: this.$t("batch"), value: "batches", width: 160 },
        {
          text: this.$t("expiration_date"),
          value: "expiration_date",
          width: 160,
        },
      ],
    };
  },
  watch: {
    async cleanList(value) {
      if (value == null) {
        await this.$store.dispatch("products/clean");
      }
    },
  },
  methods: {
    returnToList() {
      this.$router.push({
        name: "inbound.products",
      });
    },

    // ? should we download it or view it in popup
    async downloadPurchaseOrder(order) {
      console.log(order);
      this.is_loading_invoice = true;
      await supplyOrderService
        .getInvoice(order)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "order-" + order.tracking_number + ".pdf"
          );
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_invoice = false;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_invoice = false;
        });
    },

    async downloadInboundInvoice(item) {
      this.is_loading_invoice = true;
      await this.$store
        .dispatch("inboundProducts/getInvoice", item)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "inbound-" + item.tracking_number + ".pdf"
          );
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_invoice = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_invoice = false;
        });
    },

    async reopenInbound(item) {
      this.is_loading = true;
      try {
        const resultSwal = await this.$swal({
          icon: "question",
          text: this.$t("reopen_inbound_confirmation"),
          showConfirmButton: true,
          showCancelButton: true,
        });

        if (resultSwal.isConfirmed) {
          await this.$store.dispatch("inboundProducts/reopen", item);
          await this.$store.dispatch("inboundProducts/list", this.filters);
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
          this.returnToList();
        }

        this.is_loading = false;
      } catch (error) {
        this.is_loading = false;
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
  async created() {
    console.log(this.inbound.products);
    this.$store.commit("inboundProducts/SET_INBOUND", {});
    this.$store.commit("products/CLEAN_LIST");

    const id = this.$route.params.id;
    if (id) {
      const inboundEntity = await inboundProductService.show({
        id,
      });
      this.$store.commit("inboundProducts/SET_INBOUND", inboundEntity);
    }
  },
};
</script>
<style scoped>
.v-data-table__expanded__content {
  box-shadow: none !important;
}
.v-application .grey {
  background-color: #f9f9f9 !important;
  border-color: #f9f9f9 !important;
}
.v-data-table__expanded__content * {
  background-color: #f9f9f9;
}
</style>
